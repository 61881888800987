// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as Rxjs from "../../Rxjs.bs.js";
import * as Rxjs$1 from "rxjs";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as UserRoles from "../../backend/UserRoles.bs.js";
import * as Reactfire from "reactfire";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactUpdate from "rescript-react-update/src/ReactUpdate.bs.js";
import * as Auth from "rxfire/auth";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as Firestore from "rxfire/firestore";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function ShareLinkResolver$Pure(props) {
  var onAccept = props.onAccept;
  var data = props.data;
  var tmp;
  var exit = 0;
  if (data !== undefined && onAccept !== undefined) {
    var role = Core__Option.getExn(Core__Option.map(UserRoles.roleFromInt(data[0].role), UserRoles.roleI18n), undefined);
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs("p", {
                  children: [
                    "Dovolujeme si vás pozvat do výčeního místa ",
                    JsxRuntime.jsx("b", {
                          children: data[1].name
                        }),
                    ".  Vaše role bude ",
                    JsxRuntime.jsx("i", {
                          children: role
                        })
                  ]
                }),
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx("button", {
                        children: "Přijmout pozvání",
                        className: Styles.button.variantPrimary,
                        disabled: props.loading,
                        type: "button",
                        onClick: (function (param) {
                            onAccept();
                          })
                      })
                })
          ]
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs("p", {
                  children: [
                    "Omlouváme se, ale pozvánka, ",
                    JsxRuntime.jsx("b", {
                          children: "již není platná"
                        }),
                    ". Možná jste jí už přijali, nebo vypršela její platnost."
                  ]
                }),
            JsxRuntime.jsx("p", {
                  children: "Podívejte se, jestli místo, kam se chcete přihlásit, nefiguruje na vašem seznamu míst. Když\n            tam místo nenajdete, požádejte správce místa aby vám poslal novou pozvánku."
                }),
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx("a", {
                        children: "Můj seznam míst",
                        className: Styles.button.variantPrimary,
                        href: "/misto"
                      })
                })
          ]
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h1", {
                      children: "Check.beer",
                      className: Styles.utility.srOnly
                    }),
                JsxRuntime.jsx("h2", {
                      children: "Pozvánka"
                    }),
                tmp
              ],
              className: Styles.page.centered
            });
}

var Pure = {
  make: ShareLinkResolver$Pure
};

function redirectToPlace(placeId) {
  RescriptReactRouter.replace("/misto/" + placeId);
}

function pageDataRx(auth, firestore, linkId) {
  var currentUserRx = Auth.user(auth).pipe(Rxjs.keepMap(function (prim) {
            if (prim === null) {
              return ;
            } else {
              return Caml_option.some(prim);
            }
          }));
  var shareLinkRx = Firestore.docData(Db.ShareLink.$$document(firestore, linkId), { "idField": "uid" });
  var shareLinkPlaceRx = shareLinkRx.pipe(Rxjs$1.switchMap(function (maybeShareLink) {
            if (maybeShareLink !== undefined) {
              return Firestore.docData(Db.placeDocument(firestore, maybeShareLink.place), { "idField": "uid" });
            } else {
              return Rxjs$1.of(undefined);
            }
          }));
  Rxjs.op(Rxjs.op(Rxjs$1.combineLatest(currentUserRx, shareLinkPlaceRx), Rxjs$1.first()), Rxjs$1.tap(function (param) {
              var maybeShareLinkPlace = param[1];
              if (maybeShareLinkPlace === undefined) {
                return ;
              }
              var userAlreadyInPlace = Core__Option.isSome(maybeShareLinkPlace.users[param[0].uid]);
              if (userAlreadyInPlace) {
                Db.ShareLink.$$delete(firestore, linkId);
                return redirectToPlace(Db.getUid(maybeShareLinkPlace));
              }
              
            })).subscribe(function (param) {
        
      });
  return Rxjs$1.combineLatest(currentUserRx, shareLinkRx, shareLinkPlaceRx);
}

function ShareLinkResolver(props) {
  var linkId = props.linkId;
  var auth = Reactfire.useAuth();
  var firestore = Reactfire.useFirestore();
  var pageDataStatus = Reactfire.useObservable("page_ShareLinkResolver_" + linkId, pageDataRx(auth, firestore, linkId));
  var match = pageDataStatus.data;
  if (match !== undefined) {
    var shareLink = match[1];
    if (shareLink !== undefined) {
      var shareLinkPlace = match[2];
      if (shareLinkPlace !== undefined) {
        var currentUser = match[0];
        var match$1 = ReactUpdate.useReducer((function (state, action) {
                if (typeof action === "object") {
                  if (typeof state !== "object" && state === "Pending") {
                    return {
                            TAG: "Update",
                            _0: {
                              TAG: "Error",
                              _0: action._0
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
                }
                if (action !== "Run") {
                  if (typeof state !== "object" && state === "Pending") {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: "Success",
                            _1: (function (param) {
                                redirectToPlace(shareLink.place);
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
                }
                if (typeof state !== "object") {
                  switch (state) {
                    case "Ready" :
                        break;
                    case "Pending" :
                    case "Success" :
                        return "NoUpdate";
                    
                  }
                }
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: "Pending",
                        _1: (function (param) {
                            var send = param.send;
                            Core__Promise.$$catch(Db.ShareLink.acceptInvitation(firestore, linkId, currentUser.uid).then(function () {
                                      send("Resolved");
                                      return Promise.resolve();
                                    }), (function (error) {
                                    send({
                                          TAG: "Rejected",
                                          _0: error
                                        });
                                    return Promise.resolve();
                                  }));
                          })
                      };
              }), "Ready");
        var acceptInviteSend = match$1[1];
        return JsxRuntime.jsx(ShareLinkResolver$Pure, {
                    data: [
                      shareLink,
                      shareLinkPlace
                    ],
                    loading: match$1[0] === "Pending",
                    onAccept: (function () {
                        acceptInviteSend("Run");
                      })
                  });
      }
      
    }
    
  }
  return JsxRuntime.jsx(ShareLinkResolver$Pure, {});
}

var make = ShareLinkResolver;

export {
  Pure ,
  redirectToPlace ,
  pageDataRx ,
  make ,
}
/* Db Not a pure module */
