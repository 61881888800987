// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FirebaseConfig from "./firebaseConfig";

var FirebaseOptions = {};

var FirebaseApp = {};

var Analytics = {};

var Transaction = {};

var WriteBatch = {};

var User = {};

var EmailAuthProvider = {
  providerID: "password"
};

var GithubAuthProvider = {
  providerID: "github.com"
};

var GoogleAuthProvider = {
  providerID: "google.com"
};

var FederatedAuthProvider = {};

var Auth = {
  EmailAuthProvider: EmailAuthProvider,
  GithubAuthProvider: GithubAuthProvider,
  GoogleAuthProvider: GoogleAuthProvider,
  FederatedAuthProvider: FederatedAuthProvider
};

var appCheckToken = FirebaseConfig.APP_CHECK_TOKEN;

var PersistentTabManager = {};

var FirestoreLocalCache = {
  PersistentTabManager: PersistentTabManager
};

var AppCheckProvider = {};

var Messaging = {};

var Timestamp = {};

var StyledFirebaseAuth = {};

var Functions = {};

export {
  FirebaseOptions ,
  FirebaseApp ,
  Analytics ,
  Transaction ,
  WriteBatch ,
  User ,
  Auth ,
  appCheckToken ,
  FirestoreLocalCache ,
  AppCheckProvider ,
  Messaging ,
  Timestamp ,
  StyledFirebaseAuth ,
  Functions ,
}
/* appCheckToken Not a pure module */
