// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as React from "react";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as DomUtils from "../../utils/DomUtils.bs.js";
import * as UserRoles from "../../backend/UserRoles.bs.js";
import * as Reactfire from "reactfire";
import * as ChangeRole from "./ChangeRole.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as RouterUtils from "../../utils/RouterUtils.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DialogCycling from "../../components/DialogCycling/DialogCycling.bs.js";
import * as LoadingInline from "../../components/LoadingInline/LoadingInline.bs.js";
import * as SendInvitation from "./SendInvitation.bs.js";
import * as FormattedCurrency from "../../components/FormattedCurrency/FormattedCurrency.bs.js";
import * as FormattedDateTime from "../../components/FormattedDataTime/FormattedDateTime.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DialogConfirmation from "../../components/DialogConfirmation/DialogConfirmation.bs.js";
import * as Firestore from "firebase/firestore";
import * as AddFinancialTransactions from "./AddFinancialTransactions.bs.js";
import PersonDetailModuleCss from "./PersonDetail.module.css";

var classes = PersonDetailModuleCss;

function byCreatedDesc(a, b) {
  return b.createdAt.toMillis() - a.createdAt.toMillis();
}

function PersonDetail(props) {
  var placeId = props.placeId;
  var place = props.place;
  var personId = props.personId;
  var personsAll = props.personsAll;
  var person = props.person;
  var pendingTransactions = props.pendingTransactions;
  var onDeletePerson = props.onDeletePerson;
  var firestore = Reactfire.useFirestore();
  var match = Db.usePlacePersonDocumentStatus({
        idField: "uid",
        suspense: false
      }, placeId, personId);
  var maybePersonDoc = match.data;
  var match$1 = React.useState(function () {
        return "Hidden";
      });
  var setDialog = match$1[1];
  var dialogState = match$1[0];
  var hideDialog = function (param) {
    setDialog(function (param) {
          return "Hidden";
        });
  };
  var personsAllDict = React.useMemo((function () {
          return Object.fromEntries(personsAll);
        }), [personsAll]);
  var personRole = Core__Option.flatMap(Core__Option.flatMap(Caml_option.null_to_opt(person.userId), (function (userId) {
              return place.users[userId];
            })), UserRoles.roleFromInt);
  var tmp;
  if (personRole !== undefined) {
    var tmp$1;
    tmp$1 = personRole === 100 ? null : JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              " (",
              JsxRuntime.jsx("button", {
                    children: "změnit oprávnění",
                    className: Styles.link.base,
                    type: "button",
                    onClick: (function (param) {
                        setDialog(function (param) {
                              return {
                                      TAG: "ChangeRole",
                                      _0: personRole
                                    };
                            });
                      })
                  }),
              ")"
            ]
          });
    tmp = JsxRuntime.jsxs("p", {
          children: [
            "Má propojený účet a oprávnění jako ",
            JsxRuntime.jsx("b", {
                  children: UserRoles.roleI18n(personRole)
                }),
            tmp$1
          ],
          className: Styles.messageBar.base
        });
  } else {
    tmp = JsxRuntime.jsxs("p", {
          children: [
            "Nemá propojený účet. ",
            JsxRuntime.jsx("button", {
                  children: "Poslat pozvánku",
                  className: Styles.link.base,
                  type: "button",
                  onClick: (function (param) {
                      setDialog(function (param) {
                            return "SendInvitation";
                          });
                    })
                })
          ],
          className: Styles.messageBar.base
        });
  }
  var tmp$2;
  var exit = 0;
  if (maybePersonDoc !== undefined) {
    if (pendingTransactions.length !== 0 || maybePersonDoc.transactions.length !== 0) {
      exit = 1;
    } else {
      tmp$2 = JsxRuntime.jsx("p", {
            children: "Tato osoba zatím nemá účetní záznamy."
          });
    }
  } else {
    tmp$2 = JsxRuntime.jsx(LoadingInline.make, {});
  }
  if (exit === 1) {
    var transactions = maybePersonDoc.transactions;
    pendingTransactions.sort(byCreatedDesc);
    transactions.sort(byCreatedDesc);
    tmp$2 = JsxRuntime.jsxs("table", {
          children: [
            JsxRuntime.jsx("thead", {
                  children: JsxRuntime.jsxs("tr", {
                        children: [
                          JsxRuntime.jsx("th", {
                                children: "Datum",
                                scope: "col"
                              }),
                          JsxRuntime.jsx("th", {
                                children: "Částka",
                                scope: "col"
                              }),
                          JsxRuntime.jsx("th", {
                                children: "Poznámka",
                                scope: "col"
                              })
                        ]
                      })
                }),
            JsxRuntime.jsxs("tbody", {
                  children: [
                    pendingTransactions.map(function (pendingTransaction) {
                          var createdDate = pendingTransaction.createdAt.toDate();
                          var kegSerial = pendingTransaction.keg;
                          return JsxRuntime.jsxs("tr", {
                                      children: [
                                        JsxRuntime.jsx("td", {
                                              children: JsxRuntime.jsx(FormattedDateTime.make, {
                                                    value: createdDate
                                                  })
                                            }),
                                        JsxRuntime.jsx("td", {
                                              children: JsxRuntime.jsx(FormattedCurrency.make, {
                                                    value: pendingTransaction.amount
                                                  })
                                            }),
                                        JsxRuntime.jsx("td", {
                                              children: kegSerial !== null ? "Nezaúčtované: vklad za sud " + Db.formatKegSerial(kegSerial) : null
                                            })
                                      ],
                                      className: classes.unclosed
                                    }, createdDate.getTime().toString());
                        }),
                    transactions.map(function (finalTransaction) {
                          var createdDate = finalTransaction.createdAt.toDate();
                          var match = finalTransaction.keg;
                          var match$1 = finalTransaction.note;
                          var match$2 = finalTransaction.person;
                          var match$3 = finalTransaction.amount > 0;
                          return JsxRuntime.jsxs("tr", {
                                      children: [
                                        JsxRuntime.jsx("td", {
                                              children: JsxRuntime.jsx(FormattedDateTime.make, {
                                                    value: createdDate
                                                  })
                                            }),
                                        JsxRuntime.jsx("td", {
                                              children: JsxRuntime.jsx(FormattedCurrency.make, {
                                                    value: finalTransaction.amount
                                                  })
                                            }),
                                        JsxRuntime.jsx("td", {
                                              children: match$1 !== null ? match$1 : (
                                                  match !== null ? (
                                                      match$3 ? "Vklad sudu " + Db.formatKegSerial(match) : "Konzumace ze sudu " + Db.formatKegSerial(match)
                                                    ) : (
                                                      match$2 !== null ? (
                                                          match$3 ? "Převod pro " + personsAllDict[match$2].name : "Převod od " + personsAllDict[match$2].name
                                                        ) : null
                                                    )
                                                )
                                            })
                                      ]
                                    }, createdDate.getTime().toString());
                        })
                  ]
                })
          ],
          "aria-labelledby": "financial_transactions",
          className: classes.tableTransactions + " " + Styles.table.inDialog
        });
  }
  var tmp$3;
  if (typeof dialogState !== "object") {
    switch (dialogState) {
      case "Hidden" :
          tmp$3 = null;
          break;
      case "AddTransaction" :
          var highestBalanceNonCurrentPerson = Core__Array.reduce(personsAll, undefined, (function (acc, item) {
                  if (item[0] === personId || acc !== undefined && acc[1].balance >= item[1].balance) {
                    return acc;
                  } else {
                    return item;
                  }
                }));
          tmp$3 = JsxRuntime.jsx(AddFinancialTransactions.make, {
                initialCounterParty: Core__Option.getOr(Core__Option.map(highestBalanceNonCurrentPerson, (function (prim) {
                            return prim[0];
                          })), ""),
                onDismiss: hideDialog,
                onSubmit: (function (values) {
                    Db.Person.addFinancialTransaction(firestore, placeId, personId, values.person, {
                          amount: values.amount,
                          createdAt: Firestore.Timestamp.now(),
                          keg: null,
                          note: values.note === "" ? null : values.note,
                          person: values.person
                        });
                    setDialog(function (param) {
                          return "Hidden";
                        });
                  }),
                personId: personId,
                personName: person.name,
                personsAll: personsAll
              });
          break;
      case "ConfirmDeletePerson" :
          tmp$3 = JsxRuntime.jsx(DialogConfirmation.make, {
                children: JsxRuntime.jsxs("p", {
                      children: [
                        "Chystáte se odstranit osobu ",
                        JsxRuntime.jsx("b", {
                              children: person.name
                            }),
                        " z aplikace. Nemá žádnou historii konzumací ani účetní transakci. Chcete pokračovat?"
                      ]
                    }),
                className: DialogConfirmation.classes.deleteConfirmation,
                heading: "Odstranit osobu ?",
                onConfirm: (function () {
                    setDialog(function (param) {
                          return "Hidden";
                        });
                    onDeletePerson();
                  }),
                onDismiss: hideDialog,
                visible: true
              });
          break;
      case "SendInvitation" :
          tmp$3 = JsxRuntime.jsx(SendInvitation.make, {
                onDismiss: hideDialog,
                onSubmit: (async function (values) {
                    var linkId = await Db.ShareLink.upsert(firestore, placeId, personId, values.role);
                    var url = RouterUtils.createShareLink(linkId);
                    var handler = await DomUtils.share({
                          text: "Zvu tě do aplikace check.beer, kde uvidíš svůj pivní lístek.",
                          title: "Pozvánka do aplikace check.beer",
                          url: url
                        });
                    if (handler !== "Clipboard") {
                      return ;
                    }
                    window.alert("Pozvánka byla zkopírována do schránky.");
                  })
              });
          break;
      
    }
  } else {
    tmp$3 = JsxRuntime.jsx(ChangeRole.make, {
          currentRole: dialogState._0,
          onDismiss: hideDialog,
          onSubmit: (function (param) {
              Db.Place.changePersonRole(firestore, placeId, personId, param.role);
              setDialog(function (param) {
                    return "Hidden";
                  });
            }),
          personName: person.name
        });
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(DialogCycling.make, {
                      children: [
                        JsxRuntime.jsx("section", {
                              children: JsxRuntime.jsxs("dl", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("dt", {
                                                    children: "již od"
                                                  }),
                                              JsxRuntime.jsx("dd", {
                                                    children: maybePersonDoc !== undefined ? JsxRuntime.jsx(FormattedDateTime.make, {
                                                            value: maybePersonDoc.createdAt.toDate()
                                                          }) : JsxRuntime.jsx(LoadingInline.make, {})
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("dt", {
                                                    children: "naposledy"
                                                  }),
                                              JsxRuntime.jsx("dd", {
                                                    children: JsxRuntime.jsx(FormattedDateTime.make, {
                                                          value: person.recentActivityAt.toDate()
                                                        })
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx("dt", {
                                                    children: "stav konta"
                                                  }),
                                              JsxRuntime.jsx("dd", {
                                                    children: JsxRuntime.jsx(FormattedCurrency.make, {
                                                          value: person.balance,
                                                          format: FormattedCurrency.formatAccounting
                                                        })
                                                  })
                                            ]
                                          })
                                    ],
                                    className: Styles.descriptionList.inline
                                  }),
                              "aria-label": "Základní údaje"
                            }),
                        tmp,
                        props.unfinishedConsumptions.length !== 0 || pendingTransactions.length !== 0 || !(maybePersonDoc !== undefined && maybePersonDoc.transactions.length === 0) ? null : JsxRuntime.jsxs("p", {
                                children: [
                                  person.name + " nemá nezaúčtovaná piva. Dokonce nemá ani účetní záznam. Pokud jste tuto osobu přidali omylem, můžete jí nyní ",
                                  JsxRuntime.jsx("button", {
                                        children: "zcela odebrat z aplikace",
                                        className: Styles.link.base,
                                        type: "button",
                                        onClick: (function (param) {
                                            setDialog(function (param) {
                                                  return "ConfirmDeletePerson";
                                                });
                                          })
                                      }),
                                  ". S účetním záznamem to později již není možné ☝️"
                                ]
                              }),
                        JsxRuntime.jsxs("section", {
                              children: [
                                JsxRuntime.jsxs("header", {
                                      children: [
                                        JsxRuntime.jsx("h3", {
                                              children: "Účetní záznamy",
                                              id: "financial_transactions"
                                            }),
                                        React.cloneElement(JsxRuntime.jsx("button", {
                                                  children: "Nová platba",
                                                  className: Styles.button.base,
                                                  type: "button",
                                                  onClick: (function (param) {
                                                      setDialog(function (param) {
                                                            return "AddTransaction";
                                                          });
                                                    })
                                                }), personsAll.length < 2 ? ({
                                                  disabled: true,
                                                  title: "Přidejte další osoby"
                                                }) : ({}))
                                      ]
                                    }),
                                tmp$2
                              ],
                              "aria-labelledby": "financial_transactions"
                            })
                      ],
                      className: classes.root,
                      hasNext: props.hasNext,
                      hasPrevious: props.hasPrevious,
                      header: person.name,
                      onDismiss: props.onDismiss,
                      onNext: props.onNextPerson,
                      onPrevious: props.onPreviousPerson,
                      visible: true
                    }),
                tmp$3
              ]
            });
}

var make = PersonDetail;

export {
  classes ,
  byCreatedDesc ,
  make ,
}
/* classes Not a pure module */
