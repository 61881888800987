// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Place from "./pages/Place/Place.bs.js";
import * as React from "react";
import * as Homepage from "./pages/Homepage/Homepage.bs.js";
import * as MyPlaces from "./pages/MyPlaces/MyPlaces.bs.js";
import * as Core__List from "@rescript/core/src/Core__List.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as PageNotFound from "./pages/PageNotFound/PageNotFound.bs.js";
import * as PlaceSetting from "./pages/PlaceSetting/PlaceSetting.bs.js";
import * as SignInWrapper from "./components/SignInWrapper/SignInWrapper.bs.js";
import * as LoadingFullscreen from "./components/LoadingFullscreen/LoadingFullscreen.bs.js";
import * as ShareLinkResolver from "./pages/ShareLinkResolver/ShareLinkResolver.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as PlacePersonsSetting from "./pages/PlacePersonsSetting/PlacePersonsSetting.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as FirebaseAuthProvider from "./components/FirebaseAuthProvider/FirebaseAuthProvider.bs.js";
import Fn from "@oddbird/css-anchor-positioning/fn";

import './styles/index.css'
;

import '@oddbird/popover-polyfill'
;

Curry._1(Fn, undefined);

function App(props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = url.path;
  var tmp;
  var exit = 0;
  if (match) {
    switch (match.hd) {
      case "misto" :
          exit = 1;
          break;
      case "s" :
          var match$1 = match.tl;
          tmp = match$1 && !match$1.tl ? JsxRuntime.jsx(FirebaseAuthProvider.make, {
                  children: JsxRuntime.jsx(SignInWrapper.make, {
                        children: JsxRuntime.jsx(ShareLinkResolver.make, {
                              linkId: match$1.hd
                            })
                      })
                }) : JsxRuntime.jsx(PageNotFound.make, {});
          break;
      default:
        tmp = JsxRuntime.jsx(PageNotFound.make, {});
    }
  } else {
    tmp = JsxRuntime.jsx(Homepage.make, {});
  }
  if (exit === 1) {
    var match$2 = Core__List.tail(url.path);
    var tmp$1;
    if (match$2 !== undefined) {
      if (match$2) {
        var match$3 = match$2.tl;
        var placeId = match$2.hd;
        if (match$3) {
          if (match$3.hd === "nastaveni") {
            var match$4 = match$3.tl;
            tmp$1 = match$4 ? (
                match$4.hd === "osob" && !match$4.tl ? JsxRuntime.jsx(PlacePersonsSetting.make, {
                        placeId: placeId
                      }) : JsxRuntime.jsx(PageNotFound.make, {})
              ) : JsxRuntime.jsx(PlaceSetting.make, {
                    placeId: placeId
                  });
          } else {
            tmp$1 = JsxRuntime.jsx(PageNotFound.make, {});
          }
        } else {
          tmp$1 = JsxRuntime.jsx(Place.make, {
                placeId: placeId
              });
        }
      } else {
        tmp$1 = JsxRuntime.jsx(MyPlaces.make, {});
      }
    } else {
      tmp$1 = JsxRuntime.jsx(PageNotFound.make, {});
    }
    tmp = JsxRuntime.jsx(FirebaseAuthProvider.make, {
          children: JsxRuntime.jsx(SignInWrapper.make, {
                children: tmp$1
              })
        });
  }
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(tmp),
              fallback: Caml_option.some(JsxRuntime.jsx(LoadingFullscreen.make, {}))
            });
}

var make = App;

export {
  make ,
}
/*  Not a pure module */
