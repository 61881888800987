// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as Rxjs from "../../Rxjs.bs.js";
import * as Rxjs$1 from "rxjs";
import * as React from "react";
import * as Header from "../../components/Header/Header.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as DateUtils from "../../utils/DateUtils.bs.js";
import * as KegAddNew from "./KegAddNew.bs.js";
import * as KegDetail from "./KegDetail.bs.js";
import * as UserRoles from "../../backend/UserRoles.bs.js";
import * as Reactfire from "reactfire";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as PlaceStats from "./PlaceStats.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ChargedKegs from "./ChargedKegs.bs.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as PlaceHeader from "../../components/PlaceHeader/PlaceHeader.bs.js";
import * as TapsSetting from "./TapsSetting.bs.js";
import * as Auth from "rxfire/auth";
import * as BackendUtils from "../../utils/BackendUtils.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DepletedKegs from "./DepletedKegs.bs.js";
import * as BasicInfoDialog from "./BasicInfoDialog.bs.js";
import * as Firestore from "rxfire/firestore";
import * as FormattedCurrency from "../../components/FormattedCurrency/FormattedCurrency.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Firestore$1 from "firebase/firestore";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as UsePaginatedDepletedKegsData from "./UsePaginatedDepletedKegsData.bs.js";

function pageDataRx(auth, firestore, placeId) {
  var placeRef = Db.placeDocument(firestore, placeId);
  var placeRx = Rxjs.op(Firestore.docData(placeRef, { "idField": "uid" }), Rxjs.keepSome);
  var personsAllRx = Db.PersonsIndex.allEntriesSortedRx(firestore, placeId);
  var chargedKegsRx = Db.allChargedKegsRx(firestore, placeId);
  var currentUserRx = Rxjs.op(Auth.user(auth), Rxjs.keepMap(function (prim) {
            if (prim === null) {
              return ;
            } else {
              return Caml_option.some(prim);
            }
          }));
  return Rxjs$1.combineLatest(placeRx, personsAllRx, chargedKegsRx, currentUserRx);
}

function PlaceSetting(props) {
  var placeId = props.placeId;
  var auth = Reactfire.useAuth();
  var firestore = Reactfire.useFirestore();
  var match = UsePaginatedDepletedKegsData.use(undefined, placeId);
  var pageDataStatus = Reactfire.useObservable("Page_PlaceSetting_" + placeId, pageDataRx(auth, firestore, placeId));
  var match$1 = React.useState(function () {
        return "Hidden";
      });
  var setDialog = match$1[1];
  var dialogState = match$1[0];
  var hideDialog = function (param) {
    setDialog(function (param) {
          return "Hidden";
        });
  };
  var match$2 = pageDataStatus.data;
  if (match$2 === undefined) {
    return null;
  }
  var chargedKegs = match$2[2];
  var personsAll = match$2[1];
  var place = match$2[0];
  var currentUserRole = Core__Option.getExn(place.users[match$2[3].uid], undefined);
  var isUserAuthorized = function (extra) {
    return UserRoles.isAuthorized(currentUserRole, extra);
  };
  var kegsOnTapUids = Belt_Array.keepMap(Js_dict.values(place.taps), (function (maybeKegReference) {
          return Core__Option.map(maybeKegReference === null ? undefined : Caml_option.some(maybeKegReference), (function (ref) {
                        return ref.id;
                      }));
        }));
  var match$3 = Belt_Array.partition(chargedKegs, (function (keg) {
          return kegsOnTapUids.includes(Db.getUid(keg));
        }));
  var tmp;
  if (typeof dialogState !== "object") {
    switch (dialogState) {
      case "Hidden" :
          tmp = null;
          break;
      case "AddKeg" :
          tmp = JsxRuntime.jsx(KegAddNew.make, {
                onDismiss: hideDialog,
                onSubmit: (function (param) {
                    var price = param.price;
                    var resolvedDonors;
                    if (param.ownerIsDonor) {
                      var placeOwnerUid = Core__Option.getExn(Object.entries(place.users).find(function (param) {
                                  return param[1] === 100;
                                }), undefined)[0];
                      var placeOwnerPersonId = Core__Option.getExn(personsAll.find(function (param) {
                                  var userId = param[1].userId;
                                  if (userId !== null) {
                                    return userId === placeOwnerUid;
                                  } else {
                                    return false;
                                  }
                                }), undefined)[0];
                      resolvedDonors = Js_dict.fromArray([[
                              placeOwnerPersonId,
                              price
                            ]]);
                    } else {
                      resolvedDonors = param.donors;
                    }
                    Firestore$1.addDoc(Db.placeKegsCollection(firestore, placeId), {
                          beer: param.beer,
                          consumptions: {},
                          createdAt: Firestore$1.Timestamp.now(),
                          depletedAt: null,
                          donors: resolvedDonors,
                          milliliters: param.milliliters,
                          price: price,
                          recentConsumptionAt: null,
                          serial: param.serial
                        });
                    setDialog(function (param) {
                          return "Hidden";
                        });
                  }),
                personsAll: personsAll,
                placeId: placeId
              });
          break;
      case "BasicInfoEdit" :
          tmp = JsxRuntime.jsx(BasicInfoDialog.make, {
                initialValues: {
                  createdAt: DateUtils.toIsoDateString(place.createdAt.toDate()),
                  name: place.name
                },
                onDismiss: hideDialog,
                onPlaceDelete: (function () {
                    Db.Place.$$delete(firestore, placeId);
                    RescriptReactRouter.replace("/misto");
                  }),
                onSubmit: (function (values) {
                    Db.Place.update(firestore, placeId, Firestore$1.Timestamp.fromDate(DateUtils.fromIsoDateString(values.createdAt)), values.name);
                    setDialog(function (param) {
                          return "Hidden";
                        });
                  })
              });
          break;
      
    }
  } else {
    var kegId = dialogState._0;
    var currentIdx = chargedKegs.findIndex(function (keg) {
          return Db.getUid(keg) === kegId;
        });
    var hasNext = currentIdx !== -1 && currentIdx < (chargedKegs.length - 1 | 0);
    var hasPrevious = currentIdx > 0;
    var handleCycle = function (increase) {
      var allowed = increase ? hasNext : hasPrevious;
      if (!allowed) {
        return ;
      }
      var nextIdx = currentIdx + (
        increase ? 1 : -1
      ) | 0;
      var nextKegId = Db.getUid(Belt_Array.getExn(chargedKegs, nextIdx));
      setDialog(function (param) {
            return {
                    TAG: "KegDetail",
                    _0: nextKegId
                  };
          });
    };
    var formatConsumption = BackendUtils.getFormatConsumption(place.consumptionSymbols);
    var keg = Belt_Array.getExn(chargedKegs, currentIdx);
    tmp = JsxRuntime.jsx(KegDetail.make, {
          formatConsumption: formatConsumption,
          hasNext: hasNext,
          hasPrevious: hasPrevious,
          isUserAuthorized: isUserAuthorized,
          keg: keg,
          place: place,
          personsAllById: Js_dict.fromArray(personsAll),
          onDeleteConsumption: (function (consumptionId) {
              Db.Keg.deleteConsumption(firestore, placeId, kegId, consumptionId);
            }),
          onDeleteKeg: (function () {
              Db.Keg.$$delete(firestore, placeId, kegId);
              setDialog(function (param) {
                    return "Hidden";
                  });
            }),
          onDismiss: hideDialog,
          onFinalizeKeg: (function () {
              Db.Keg.finalize(firestore, placeId, kegId);
              setDialog(function (param) {
                    return "Hidden";
                  });
            }),
          onPreviousKeg: (function () {
              handleCycle(false);
            }),
          onNextKeg: (function () {
              handleCycle(true);
            })
        });
  }
  return JsxRuntime.jsxs(FormattedCurrency.Provider.make, {
              value: place.currency,
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(PlaceHeader.make, {
                              buttonRightSlot: UserRoles.isAuthorized(currentUserRole, 100) ? JsxRuntime.jsxs("button", {
                                      children: [
                                        JsxRuntime.jsx("span", {
                                              children: "✏️"
                                            }),
                                        JsxRuntime.jsx("span", {
                                              children: "Změnit"
                                            })
                                      ],
                                      className: Header.classes.buttonRight,
                                      type: "button",
                                      onClick: (function (param) {
                                          setDialog(function (param) {
                                                return "BasicInfoEdit";
                                              });
                                        })
                                    }) : null,
                              createdTimestamp: place.createdAt,
                              placeName: place.name
                            }),
                        JsxRuntime.jsxs("main", {
                              children: [
                                JsxRuntime.jsx(PlaceStats.make, {
                                      chargedKegsValue: Core__Array.reduce(chargedKegs, 0, (function (sum, keg) {
                                              return sum + keg.price | 0;
                                            })),
                                      isUserAuthorized: isUserAuthorized,
                                      personsCount: personsAll.length
                                    }),
                                JsxRuntime.jsx(TapsSetting.make, {
                                      place: place,
                                      placeId: placeId,
                                      tappedChargedKegs: match$3[0],
                                      untappedChargedKegs: match$3[1]
                                    }),
                                JsxRuntime.jsx(ChargedKegs.make, {
                                      chargedKegs: chargedKegs,
                                      onAddNewKeg: (function () {
                                          setDialog(function (param) {
                                                return "AddKeg";
                                              });
                                        }),
                                      onKegDetail: (function (kegId) {
                                          setDialog(function (param) {
                                                return {
                                                        TAG: "KegDetail",
                                                        _0: kegId
                                                      };
                                              });
                                        })
                                    }),
                                JsxRuntime.jsx(DepletedKegs.make, {
                                      maybeFetchMoreDepletedKegs: match[1],
                                      maybeDepletedKegs: match[0]
                                    })
                              ]
                            })
                      ],
                      className: Styles.page.narrow
                    }),
                tmp
              ]
            });
}

var make = PlaceSetting;

export {
  pageDataRx ,
  make ,
}
/* Db Not a pure module */
