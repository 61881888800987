// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "../../styles/components/Styles.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import SectionWithHeaderModuleCss from "./SectionWithHeader.module.css";

var classes = SectionWithHeaderModuleCss;

function SectionWithHeader(props) {
  var headerId = props.headerId;
  return JsxRuntime.jsxs("section", {
              children: [
                JsxRuntime.jsxs("header", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: props.headerSlot,
                              id: headerId
                            }),
                        props.buttonsSlot
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: props.children,
                      className: Styles.box.base
                    })
              ],
              "aria-labelledby": headerId,
              className: classes.root + " " + Core__Option.getOr(props.className, "")
            });
}

var make = SectionWithHeader;

export {
  classes ,
  make ,
}
/* classes Not a pure module */
