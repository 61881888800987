// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Firebase from "../../backend/Firebase.bs.js";
import * as Reactfire from "reactfire";
import * as Auth from "firebase/auth";
import * as JsxRuntime from "react/jsx-runtime";
import * as Analytics from "firebase/analytics";
import * as AppCheck from "firebase/app-check";
import * as Functions from "firebase/functions";

function FirebaseAuthProvider(props) {
  var app = Reactfire.useFirebaseApp();
  var appCheck = AppCheck.initializeAppCheck(app, {
        provider: new AppCheck.ReCaptchaV3Provider(Firebase.appCheckToken),
        isTokenAutoRefreshEnabled: true
      });
  var auth = Auth.getAuth(app);
  auth.languageCode = "cs";
  var analytics = Analytics.getAnalytics(app);
  var functions = Functions.getFunctions(app);
  return JsxRuntime.jsx(Reactfire.AppCheckProvider, {
              sdk: appCheck,
              children: JsxRuntime.jsx(Reactfire.AnalyticsProvider, {
                    sdk: analytics,
                    children: JsxRuntime.jsx(Reactfire.AuthProvider, {
                          sdk: auth,
                          children: JsxRuntime.jsx(Reactfire.FunctionsProvider, {
                                sdk: functions,
                                children: props.children
                              })
                        })
                  })
            });
}

var make = FirebaseAuthProvider;

export {
  make ,
}
/* Firebase Not a pure module */
