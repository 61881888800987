// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FirebaseConfig from "./firebaseConfig";

var firebaseConfig = FirebaseConfig.firebaseConfig;

var AnalyticsProvider = {};

var AuthProvider = {};

var FirebaseAppProvider = {};

var FunctionsProvider = {};

var FirestoreProvider = {};

export {
  firebaseConfig ,
  AnalyticsProvider ,
  AuthProvider ,
  FirebaseAppProvider ,
  FunctionsProvider ,
  FirestoreProvider ,
}
/* firebaseConfig Not a pure module */
