// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Db from "../../backend/Db.bs.js";
import * as Rxjs from "../../Rxjs.bs.js";
import * as Rxjs$1 from "rxjs";
import * as React from "react";
import * as Header from "../../components/Header/Header.bs.js";
import * as Styles from "../../styles/components/Styles.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as EditUser from "./EditUser.bs.js";
import * as LogUtils from "../../utils/LogUtils.bs.js";
import * as PlaceAdd from "./PlaceAdd.bs.js";
import * as Reactfire from "reactfire";
import * as Core__Null from "@rescript/core/src/Core__Null.bs.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RouterUtils from "../../utils/RouterUtils.bs.js";
import * as Auth from "rxfire/auth";
import * as Auth$1 from "firebase/auth";
import * as SectionWithHeader from "../../components/SectionWithHeader/SectionWithHeader.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import MyPlacesModuleCss from "./MyPlaces.module.css";

var classes = MyPlacesModuleCss;

var gitShortSha = import.meta.env.VITE_GIT_SHORT_SHA;

function MyPlaces$Pure(props) {
  var usersPlaces = props.usersPlaces;
  var onSignOut = props.onSignOut;
  var onPlaceAdd = props.onPlaceAdd;
  var currentUser = props.currentUser;
  var userDisplayName = Core__Null.getExn(currentUser.displayName);
  var sinceDate = new Date(currentUser.metadata.creationTime);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Header.make, {
                      buttonLeftSlot: JsxRuntime.jsxs("button", {
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: "🚴‍♂️"
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: "Odhlásit"
                                  })
                            ],
                            className: Header.classes.buttonLeft,
                            type: "button",
                            onClick: (function (param) {
                                onSignOut();
                              })
                          }),
                      buttonRightSlot: JsxRuntime.jsxs("button", {
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: "⚙️"
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: "Nastavení"
                                  })
                            ],
                            className: Header.classes.buttonRight,
                            type: "button",
                            onClick: props.onSettingsClick
                          }),
                      headingSlot: userDisplayName,
                      subheadingSlot: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                            id: "Place.established",
                            defaultMessage: "Již od {time}",
                            values: {
                              time: JsxRuntime.jsx("time", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                          value: sinceDate
                                        }),
                                    dateTime: sinceDate.toISOString()
                                  })
                            }
                          })
                    }),
                JsxRuntime.jsx("main", {
                      children: JsxRuntime.jsx(SectionWithHeader.make, {
                            children: usersPlaces.length !== 0 ? JsxRuntime.jsx("nav", {
                                    children: JsxRuntime.jsx("ul", {
                                          children: usersPlaces.map(function (place) {
                                                var stringId = Db.getUid(place);
                                                var newrecord = Caml_obj.obj_dup(RouterUtils.createAnchorProps(undefined, "./" + stringId));
                                                return JsxRuntime.jsxs("li", {
                                                            children: [
                                                              JsxRuntime.jsx("a", (newrecord.className = Styles.utility.breakout, newrecord.children = place.name, newrecord)),
                                                              JsxRuntime.jsxs("span", {
                                                                    children: [
                                                                      "Založeno: ",
                                                                      JsxRuntime.jsx(ReactIntl.FormattedDate, {
                                                                            value: place.createdAt.toDate(),
                                                                            year: "numeric",
                                                                            month: "numeric",
                                                                            day: "numeric"
                                                                          })
                                                                    ]
                                                                  })
                                                            ]
                                                          }, stringId);
                                              }),
                                          className: Styles.list.base + " " + classes.list
                                        })
                                  }) : JsxRuntime.jsxs("p", {
                                    children: [
                                      "Seznam vašich míst je prázdný, nechte se někam pozvat 🍻 nebo ",
                                      JsxRuntime.jsx("button", {
                                            children: "založte nové místo pro vaše přátele.",
                                            className: Styles.link.base,
                                            type: "button",
                                            onClick: (function (param) {
                                                onPlaceAdd();
                                              })
                                          })
                                    ],
                                    className: SectionWithHeader.classes.emptyMessage
                                  }),
                            buttonsSlot: JsxRuntime.jsx("button", {
                                  children: "Nové místo",
                                  className: Styles.button.base,
                                  type: "button",
                                  onClick: (function (param) {
                                      onPlaceAdd();
                                    })
                                }),
                            headerId: "my_places",
                            headerSlot: "Moje místa"
                          })
                    })
              ],
              className: Styles.page.narrow
            });
}

var Pure = {
  make: MyPlaces$Pure
};

function pageDataRx(auth, firestore) {
  var currentUserRx = Rxjs.op(Auth.user(auth), Rxjs.keepMap(function (prim) {
            if (prim === null) {
              return ;
            } else {
              return Caml_option.some(prim);
            }
          }));
  var userPlacesRx = Rxjs.op(currentUserRx, Rxjs$1.switchMap(function (user) {
            return Db.placesByUserIdRx(firestore, user.uid);
          }));
  return Rxjs$1.combineLatest(currentUserRx, userPlacesRx);
}

function MyPlaces(props) {
  LogUtils.usePageView("MyPlaces");
  var firestore = Reactfire.useFirestore();
  var auth = Reactfire.useAuth();
  var pageDataStatus = Reactfire.useObservable("Page_MyPlaces", pageDataRx(auth, firestore));
  var match = React.useState(function () {
        return "Hidden";
      });
  var setDialogState = match[1];
  var hideDialog = function (param) {
    setDialogState(function (param) {
          return "Hidden";
        });
  };
  var fromHomepage = RouterUtils.isFromHomepage(RescriptReactRouter.useUrl(undefined, undefined));
  var match$1 = pageDataStatus.data;
  var tmp;
  var exit = 0;
  if (fromHomepage) {
    if (match$1 !== undefined) {
      var match$2 = match$1[1];
      if (match$2.length !== 1) {
        exit = 1;
      } else {
        var onePlaceOnly = match$2[0];
        var singlePlaceLocation = RouterUtils.resolveRelativePath("./" + Db.getUid(onePlaceOnly));
        RescriptReactRouter.replace(singlePlaceLocation);
        tmp = null;
      }
    } else {
      tmp = null;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (match$1 !== undefined) {
      var currentUser = match$1[0];
      var userDisplayName = Core__Null.getExn(currentUser.displayName);
      var tmp$1;
      switch (match[0]) {
        case "Hidden" :
            tmp$1 = null;
            break;
        case "AddPlace" :
            tmp$1 = JsxRuntime.jsx(PlaceAdd.make, {
                  initialPersonName: userDisplayName,
                  onDismiss: hideDialog,
                  onSubmit: (async function (param) {
                      var placeRef = await Db.Place.add(firestore, param.personName, param.placeName, currentUser.uid);
                      return RescriptReactRouter.push(RouterUtils.resolveRelativePath("./" + placeRef.id));
                    })
                });
            break;
        case "EditUser" :
            tmp$1 = JsxRuntime.jsx(EditUser.make, {
                  connectedEmail: Core__Null.getExn(currentUser.email),
                  initialName: userDisplayName,
                  onDismiss: hideDialog,
                  onSubmit: (async function (values) {
                      await Auth$1.updateProfile(currentUser, {
                            displayName: values.name
                          });
                      return setDialogState(function (param) {
                                  return "Hidden";
                                });
                    }),
                  onChangePassword: (async function (values) {
                      var credential = Auth$1.EmailAuthProvider.credential(Core__Null.getExn(currentUser.email), values.oldPassword);
                      await Auth$1.reauthenticateWithCredential(currentUser, credential);
                      await Auth$1.updatePassword(currentUser, values.newPassword);
                    })
                });
            break;
        
      }
      tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(MyPlaces$Pure, {
                    currentUser: currentUser,
                    onPlaceAdd: (function () {
                        setDialogState(function (param) {
                              return "AddPlace";
                            });
                      }),
                    onSignOut: (function () {
                        RescriptReactRouter.push("/");
                        Auth$1.signOut(auth);
                        setTimeout((function () {
                                window.location.reload();
                              }), 0);
                      }),
                    onSettingsClick: (function (param) {
                        setDialogState(function (param) {
                              return "EditUser";
                            });
                      }),
                    usersPlaces: match$1[1]
                  }),
              tmp$1
            ]
          });
    } else {
      tmp = null;
    }
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                tmp,
                JsxRuntime.jsx("footer", {
                      children: "v.\xA0" + gitShortSha,
                      className: classes.footer
                    })
              ]
            });
}

var make = MyPlaces;

export {
  classes ,
  gitShortSha ,
  Pure ,
  pageDataRx ,
  make ,
}
/* classes Not a pure module */
